@import "styles/base/variables";

#portal-home {
  padding: 0 4rem 2rem;
  background: #fff;
  color: $new-main-color;
  min-height: 50rem;

  section {
    padding: 1.3rem 0;
  }
  .fiduciary-infos {
    background-color: $new-very-light-gray;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    padding: 2rem;
    h1,
    h4 {
      color: $new-title-color;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    .fiduciary-contact {
      color: $new-title-color;
      margin-bottom: 1rem;
      span {
        margin-left: 0.5rem;
      }
      i {
        color: $new-gray;
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
    .other-infos {
      color: $new-gray;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  h2 {
    text-transform: uppercase;
    font-weight: 600;
    color: $portal-new-blue;
    font-size: 1.4rem;
    margin: 0;
  }
  h3 {
    font-size: 1rem;
    color: $new-gray;
  }
  .news-category {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1.5rem;
    color: #fff;
    border-radius: 0 0 0 5px;
  }
  .show-more {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $new-light-gray;
    border-radius: 50%;
    left: 50%;
    cursor: pointer;
    font-weight: bold;
  }
  .new-btn {
    height: 3rem;
    width: 10rem;
    text-align: center;
    border: 1px solid #f3faff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
    color: $portal-new-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s background-color, color ease-in-out;
    &:hover,
    &.activated {
      background-color: $portal-new-blue;
      color: #fff;
    }
  }
}
#portal-header {
  //padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fid-logo {
    max-width: 15%;
    transition: 0.3s max-width ease-in-out;
  }
  .portal-sub-menu {
    display: flex;
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    width: 100%;
    padding: 1rem 4rem;
    .fid-logo {
      max-width: 10%;
    }
    & + #portal-infos {
      margin-top: 7.625rem;
    }
  }
}
#portal-infos {
  .media-caroussel {
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    padding-top: 0;
    min-height: 18rem;
    li {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 10%;
    left: 45%;
    bottom: 0.5rem;
  }
}
#fiduciary-news {
  position: relative;
  .news-container {
    margin-top: 1rem;
    position: relative;
    overflow: hidden;
    background-color: $new-very-light-gray;
  }
  .news-titles {
    //background-color: #fff;
    li {
      position: relative;
      background: $new-light-gray;
      padding: 0.75rem 2rem 1rem;
      margin-top: 2px;
      border-left: 1px solid;
      cursor: pointer;
      transition: background-color, border-left 0.2s ease-in-out;
      &:first-child {
        margin-top: 0;
      }
      &.active {
        background: $new-very-light-gray;
        border-left: 4px solid;
      }
      .news-date {
        color: $new-gray;
        font-size: 0.7rem;
      }
      h4 {
        color: $new-title-color;
        font-size: 1.1rem;
        font-weight: 500;
        margin: 0;
        line-height: 1.2;
      }
    }
  }
  .news-content {
    font-weight: 400;
    padding: 2rem 3rem;
    border-radius: 0 5px 5px 0;
    top: 0;
    right: 0;
    &.news-content-absolute {
      position: absolute;
    }
    .article-content {
      display: none;
      transition: display 0.5s ease-in-out;
      &.active {
        display: block;
      }
    }
    & > li {
      .news-media {
        max-width: 45%;
        border-radius: 5px;
        margin: 0 2% 1rem 0;
        float: left;
        overflow: hidden;
        &.is-album {
          width: 100%;
          height: 15rem;
          position: relative;
        }
      }
      h4 {
        color: $new-title-color;
        font-size: 1.2rem;
        font-weight: 600;
      }
      h5 {
        color: $new-main-color;
        font-size: 1.1rem;
        border-left: 3px solid;
        font-weight: 500;
        margin-left: 47%;
        padding-left: 0.5rem;
        line-height: 1;
      }
      .news-authors {
        border-top: 1px solid $new-light-gray;
        border-bottom: 1px solid $new-light-gray;
        padding: 0.5rem 0;
        position: relative;
        margin: 0.7rem 0 0.7rem 47%;
        min-height: 1.5rem;
        .news-author {
          display: flex;
          align-items: center;
          h6 {
            font-size: 0.9rem;
            font-weight: 500;
            margin: 0;
            margin-left: 1rem;
          }
          .news-date {
            color: $new-gray;
            font-size: 0.8rem;
            margin-left: 1rem;
          }
          &.more {
            .news-author-avatar {
              border: 2px solid #fff;
              margin-left: -1.2rem;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .news-author-avatar {
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .help-display-full {
    cursor: pointer;
  }
  .display-full-news {
    position: absolute;
    bottom: 1.3rem;
    left: 0;
    right: 0;
    .display-news {
      background-image: linear-gradient(
        transparent,
        $new-very-light-gray,
        $new-very-light-gray
      );
      position: relative;
      height: 4rem;
      .show-more {
        top: 2.75rem;
      }
    }
  }
}
#fiduciary-team {
  position: relative;
  .team-list {
    &.big-team {
      max-height: 42rem;
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2rem;
        background-color: $new-very-light-gray;
      }
    }
    padding: 2rem;
    background-color: $new-very-light-gray;
    border-radius: 5px;
    & > li {
      background-color: $new-light-gray;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 1rem;
      .official-avatar {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 16rem;
      }
      .official-info {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 2rem 1rem;
        height: 18rem;
        h4 {
          font-size: 1.2rem;
          font-weight: 600;
          text-transform: uppercase;
        }
        .other-infos {
          word-break: break-word;
        }
        .function {
          color: $portal-new-blue;
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }
        .official-social {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 1rem;
          li {
            background-color: $new-very-light-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .show-more {
    bottom: 0;
    z-index: 1;
  }
}
#portal-docs {
  .docs-container {
    background-color: $new-very-light-gray;
    border-radius: 5px;
    padding: 2rem;
    align-items: center;
    .doc-presentation {
      padding-right: 1rem;
      h4 {
        font-weight: 600;
        font-size: 1.3rem;
      }
      p {
        margin: 0;
      }
    }
    .doc-download {
      padding: 2rem;
      background-color: $new-light-gray;
      border-radius: 5px;
      p {
        font-weight: 600;
        font-size: 1rem;
        &:before {
          content: "";
          width: 0.4rem;
          height: 0.4rem;
          background-color: $portal-new-blue;
          border-radius: 50%;
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
      .download-file {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .doc-infos {
        color: $new-gray;
        font-weight: 400;
        font-size: 0.8rem;
        margin-right: 1rem;
      }
      .new-btn {
        background-color: $new-very-light-gray;
        height: 2.5rem;
        &:hover {
          background-color: $portal-new-blue;
        }
      }
    }
  }
}
#portal-contact {
  .contact-container {
    padding: 2rem;
    background-color: $new-very-light-gray;
    border-radius: 5px;
  }
  .fiduciary-infos,
  .fiduciary-schedules {
    background-color: $new-light-gray;
    padding: 2rem 1rem;
    border-radius: 5px;
  }
  .fiduciary-schedules {
    h4 {
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    table {
      margin: 0;
    }
    td {
      padding: 0.25rem;
    }
    .day {
      color: $portal-new-blue;
    }
    .disabled {
      color: $new-gray;
    }
  }
  .contact-map {
    border-radius: 5px;
    overflow: hidden;
  }
}
