@import "styles/base/variables";

.viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
}

.media_container {
  position: relative;
  background-color: #fff;
  padding: 1.2rem 2rem;
  height: 100%;
  overflow: auto;
  h3 {
    width: 100%;
    padding: 0.5rem 0;
    color: $new-main-color;
    font-size: 1.2rem;
    margin: 0;
    font-weight: 600;
  }
  &.isDoc {
    padding: 0;
    .media {
      margin-top: 0;
    }
  }
}

.pdf_viewer {
  border: none;
  background: none;
}
.viewArrow {
  border: solid #fff;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
}
.nextArrow {
  transform: translate(0px, -50%) rotate(-45deg);
}
.prevArrow {
  transform: translate(0px, -50%) rotate(135deg);
}
.organization {
  margin-top: 1.2rem;
  border-left: 3px solid $portal-new-blue;
  padding-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.2;
  color: $new-main-color;
}
.media {
  margin-top: 1.2rem;
  width: 100%;
  box-shadow: inset 0 -7px #3e3e3e;
  & > * {
    width: 100%;
  }
}
.download {
  display: flex;
  justify-content: flex-end;
  a {
    height: 1.75rem;
    display: flex;
    align-items: center;
    padding: 0 0.75rem 0 1.25rem;
    background: $portal-new-blue;
    color: #fff;
    font-weight: 500;
    border-radius: 2rem;
    font-size: 0.7rem;
    text-transform: uppercase;
    i {
      margin-left: 0.75rem;
      font-size: 0.875rem;
      font-weight: bold;
    }
    &:hover {
      color: #fff;
    }
  }
}
.rightSide {
  background-color: $new-very-light-gray;
  color: $new-main-color;
  height: 100%;
  overflow: auto;
}
.tags {
  display: flex;
  li {
    height: 1.375rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $new-main-color;
    font-size: 0.625rem;
    margin-right: 0.25rem;
    border-radius: 2px;
    background-color: $new-light-gray;
    text-transform: uppercase;
  }
}
