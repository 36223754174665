@import "styles/base/variables";

.articleOrganization {
  padding: 0 4px 4px;
  background: #fff;
  border-radius: 5px;

  &__logo {
    width: 70%;
    height: auto;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #29394d;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    line-height: 1.25;
    //margin-top: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 1.75rem 1rem 14px;
  }

  &__subscribe {
    margin-top: 2rem;
  }
}

.subscribeSection {
  background-color: #f1f2f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 14px 2rem;

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    text-align: center;
    color: #3c4e64;
    margin-bottom: 1rem;
  }

  &__subtext {
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 1.25;
    text-align: center;
    color: #6d7f92;
    margin-bottom: 1rem;
  }

  &__input {
    background-color: #fafbfb;
    border-radius: 4px;
    border: none;
    height: 45px;
    color: #6d7f92;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__btn {
    background: #3c4e64;
    border-color: #3c4e64;
    width: 100%;
    color: #ffffff;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;

    padding: 12px;
    cursor: pointer;

    &:hover {
      background: #1890ff;
      border-color: #1890ff;
    }
  }
}
