.ReactModal__Overlay {
  z-index: 10;
  background-color: rgba(41, 57, 77, 0.4) !important;
}

.ReactModal__Content {
  transform: scale(0.1);
}

.ReactModal__Content--after-open {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.ReactModal__Content--before-close {
  transform: scale(0.1);
}

.rmodal {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  padding: 1rem;
  &__next,
  &__previous {
    cursor: pointer;
    background-color: $reveal-bg;
    border: 1px solid #fff;
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.25rem;
    border-radius: 30px;
    z-index: 10;
  }

  &__close {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -1.125rem;
    z-index: 1;
    width: 2.25rem;
    height: 2.25rem;
    top: -1.125rem;
    background-color: #fff;
    border: 1px solid #f1f2f4;
    border-radius: 50%;
    .close {
      width: 100%;
      height: 100%;
    }
  }

  &__next {
    right: 2.25rem;

    .next {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__previous {
    right: 5rem;

    .previous {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__body {
    background-color: #fff;
    height: 100%;
  }
}