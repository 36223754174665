@import "./imports";
// base
@import "base/reset";
@import "base/foundation-settings";
@import "base/variables";
@import "base/mixins";
@import "base/functions";
@import "base/animations";

// foundation
@import "foundation";
@include foundation-everything(true);

@import "~tamtam-components/build/css/main.css";

// motion-ui
// @import 'motion-ui';
// @include motion-ui-transitions;

// Slick slider
@import "base/slick.scss";
@import "base/slick-theme.scss";
@import "base/slick-pages.scss";

@import "./components/menu";
@import "./components/footer";
@import "./components/reveal";

html,
body {
  background: #fafbfb;
}

body {
  margin: 0;
  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
