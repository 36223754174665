.album {
  position: relative;
  background-image: url("/img/album.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  padding-top: 80%;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .checkIcon {
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    color: #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -30%);
    background: #1e9aed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50px;
  }

  &__selected {
    background-image: url("/img/album_light.svg");
  }

  &__content {
    position: absolute;
    top: 32px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
