@import "styles/imports";

.PageList {
  background: #fafbfb;
  padding-bottom: 4rem;
  iframe {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: top left;
    opacity: 0.9;
  }
}

.header {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  background: #f3faff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  margin-bottom: 1rem;
  // padding: 0 1rem;
  z-index: 0;
}

.headerContent {
  display: flex;
  width: 100%;
  max-width: 1278px;
  margin: auto;
  padding: 30px 40px;
}

.headerIcon {
  display: flex;
  justify-content: flex-end;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    width: 1px;
    background-color: #ffffff;
    box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
  }
  img {
    width: 46px;
    padding-right: 12px;
    margin: 18px 0;
  }
}

.headerInfo {
  width: 80%;
  display: flex;
  align-items: center;
  padding-left: 34px;
  * {
    font-family: $body-font-family;
    font-style: normal;
    font-weight: normal;
    margin: 0;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    color: #29394d;
  }

  p {
    font-size: 12px;
    line-height: 14px;
    color: #3c4e64;
  }
}

.section {
  width: 100%;
  // background-color: #fff;
}

.sectionContent,
.headerContent {
  width: 100%;
  max-width: 1278px;
  margin: auto;
  padding: 50px 40px;
}

.pagesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.page,
.createPage {
  flex-shrink: 0;
  width: calc(20% - 20px);
  margin-bottom: 3rem;
  margin-right: 20px;
}

.createPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 311px;
  background: #ffffff;
  border: 1.5px dashed #b2bcc6;
  border-radius: 4px;

  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #18a0fb;
  cursor: pointer;

  svg {
    margin-bottom: 11px;
  }
}

.page {
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 200ms ease;
  cursor: pointer;

  &:hover {
    .pageImage {
      filter: none;
      border: 2px solid #18a0fb;
      transition: all 200ms ease;
    }

    .pageLink {
      background: #2495e1;
      transition: all 200ms ease;
    }
  }
}

.pageImage {
  position: relative;
  width: 100%;
  height: 311px;

  border: 2px solid #ffffff;
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 1rem 1rem 0;
  background: #fafbfb;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    .pageInfo {
      transition: all 300ms ease;
      height: 100%;
      background: rgba(255, 255, 255, 0.95);
    }

    .pageActionsContainer {
      display: flex;
    }
  }
}

.pageInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 75px;
  width: 100%;
  box-shadow: 0px -2px 4px -3px #aaa;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 4px 4px;
  padding: 13px 18px;
}

.pageDate {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: lowercase;
  color: #6d7f92;
  margin-bottom: 5px;
}

.pageName {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #29394d;
}

.pageLink {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 141px;
  min-height: 36px;

  background: #b2bcc6;
  border-radius: 50px;
  padding: 11px 19px;

  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;

  svg {
    margin-right: 9px;
  }
}

.pageActionsContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.pageAction {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  margin: 0 6px;
  color: #fff;
  background: #18a0fb;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    box-shadow: none;
    opacity: 0.6;
  }

  &Delete {
    background: #f9626d;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
}

.modalClose {
  position: relative;
  height: 62px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;

  svg {
    cursor: pointer;
    margin-right: 8px;
  }
}

.modalIframeContainer {
  width: 100%;
  height: 100%;
  background-color: #fafbfb;
  padding: 2rem 4rem;
  iframe {
    border: none;
    appearance: none;
    width: 100%;
    height: 100%;
    border: 1.25rem solid #fafbfb;
    border-radius: 4px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotating {
  animation: rotation 1s infinite linear;
}
