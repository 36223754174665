@import "styles/base/variables";

.nl {
  height: 25rem;
  background: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  iframe {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: top left;
  }
}
.infos {
  position: absolute;
  border: 0;
  background: rgba(255, 255, 255, 0.98);
  height: 5rem;
  top: 20rem;
  left: 0;
  right: 0;
  box-shadow: 0px -6px 11px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 0 0 5px 5px;
  h3 {
    font-size: 15px;
    margin: 0;
    font-weight: 500;
    margin-bottom: 4px;
    cursor: pointer;
    text-align: center;
    max-height: 40px;
    overflow: auto;
    line-height: 1.25;
  }
  h4 {
    font-size: 0.75rem;
    margin: 0;
    text-align: center;
  }
  a {
    color: #0a0a0a;
  }
}
