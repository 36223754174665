.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}

.modalClose {
  position: relative;
  height: 62px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;

  svg {
    cursor: pointer;
    margin-right: 8px;
  }
}

.modalBody {
  width: 100%;
  height: 100%;
  background-color: #fafbfb;
  padding: 2rem 4rem;
}
.bloc {
  .album_head {
    background: url('/img/rectangle.jpg') no-repeat center;
    background-size: cover;
    height: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 17%;
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 10px;
    overflow: hidden;
    h1, h2 {
      color: #fff;
      text-shadow: 0px 0px 3px rgba(0,0,0,.5);
    }
    h1 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.675rem;
    }
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 0.75rem;
    margin-bottom: 1rem;
    color: #3c4e64;
    width: 100%;
  }
  h2 {
    font-size: 1rem;
    padding: 0 0.75rem;
    margin-bottom: 2rem;
    color: #3c4e64;
  }
  iframe {
    width: 100%;
    height: 100vh;
    box-shadow: none;
    border: none;
  }
}
.fetching {
  width: 100%;

  .bigArticle {
    height: 30rem;
    background-color: #eee;
    margin-bottom: 20px;
  }

  .media {
    width: 6rem;
    height: 26px;
    background-color: #eee;
    margin-bottom: 6px;
  }
}

.mediaSelected {
  transition: all 0.5s linear;
  margin-bottom: 620px;

  > div:first-child > div {
    border: 1px solid #000;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #000;
      position: absolute;
      left: 48%;
      bottom: -10px;
    }
  }
}
.mediaViewer {
  display: block;
  position: absolute;
  width: 90%;
  left: 5%;
  height: 600px;
}
.albumT3 {
  background-color: #fff;
  padding: 1rem 2rem;
  & > h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #29394e;
  }
  ul {
    margin: 0;
    padding-left: 3rem;
  }
}
