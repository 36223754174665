@import "styles/base/variables";
@import "styles/base/functions";
@import "styles/base/mixins";

.album {
  height: 20rem;
  padding: 1.75rem 0.75rem 0;
  & > * {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.08);
    position: relative;
    &:before,
    &:after {
      content: "";
      width: 90%;
      position: absolute;
      top: -4px;
      left: 5%;
      transition: top 0.15s ease-in-out;
      border-top: 1px solid #afb7bc;
    }
    &:after {
      width: 94%;
      left: 3%;
      top: -2px;
    }
    &:hover {
      &:before {
        top: -6px;
      }
      &:after {
        top: -3px;
      }
    }
  }
}
.overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0,
    transparent 50%,
    transparent
  );
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1rem 1rem;
  h3 {
    line-height: 1.125;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.125rem;
  }
  .countMedias {
    font-size: 14px;
    font-weight: 600;
  }
}
.mediaContent {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 4.125rem;
  }
  .pdf {
    position: absolute;
    img {
      width: 2rem;
      margin: 1rem;
      box-shadow: 4px 4px 4px -3px rgba(0, 0, 0, 0.3);
    }
  }
}
.docInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.play {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
