@import "styles/base/variables";
@import "styles/base/functions";
@import "styles/base/mixins";

.media {
  height: 13rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  /* width: 100%; */
  color: #3c4e64;
  &.mediaT5 {
    align-items: center;
    height: auto;
    .mediaContent {
      width: 6rem;
      height: 6rem;
      margin-right: 1rem;
    }
    h4 {
      font-size: 1.125rem;
      margin-bottom: 0;
      font-weight: 500;
      line-height: 1.25;
    }
    .info {
      span {
        color: #A5A5A5;
        font-size: 14px;
      }
    }
  }
  & > div {
    width: 100%;
    height: 100%;
    // overflow: hidden;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
  &.docPreview {
    height: 25rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  &.pptPreview > div {
    background-size: contain;
    background-color: #fff;
  }
  .infos {
    position: absolute;
    background: rgba(255, 255, 255, 0.98);
    height: 5rem;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 0 0 5px 5px;
    opacity: 0.9;
    h3 {
      font-size: 1rem;
      font-weight: 600;
      overflow-y: auto;
      display: block;
      max-height: 2.25rem;
      line-height: 1.25;
      margin: 0;
    }
    span {
      font-size: 0.75rem;
    }
  }
  &.col-12 {
    height: 35rem;
  }
  &.col-24 {
    height: 40rem;
    position: relative;
  }
  &.col-6 {
    height: 30rem;
  }
  &.col-4, &.col-8 {
    height: 25rem;
  }
  .overflow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0;
    background: rgba(0, 0, 0, .25);
    color: #fff;
    padding: 3rem 12rem;
    width: calc(100% - 1rem);
    & > * {
      text-shadow: 0px 1px 1px #000;
    }
    h1 {
      font-weight: 500;
      margin-bottom: 3rem;
      text-transform: uppercase;
      font-size: 2.5rem;
      text-align: center;
    }
    p {
      font-size: 1rem;
      margin-bottom: 3rem;
      text-align: center;
      font-size: 1.25rem;
    }
    ul {
      display: flex;
      text-shadow: none;
    }
    li {
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      font-size: 14px;
      background-color: #ccc;
      color: #29394d;
      font-weight: 500;
      margin: 4px 4px 0 0;
      border-radius: 2px;
    }
  }
  .countDocs {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 2rem;
    i {
      margin-right: 1rem;
    }
  }
}
.mediaContent {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px $shadow-color;

  img {
    width: 4.125rem;
  }
}
.docs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem 1rem;
  box-shadow: 0px 0px 10px $shadow-color;
  background-color: #fff;
  img {
    width: 2.7rem;
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
  }
  span {
    font-size: 0.7rem;
    font-weight: 400;
    color: $new-gray;
  }
}
.docInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.play {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

