@import "styles/base/variables";

.cycle {
  padding: .5rem 2rem;
  
}
.title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 25px;
    color: #fff;
    margin: .75rem 0;
}
.banner {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 2rem;
    color: #fff;
    width: calc(100vw - 4rem);
    min-height: 465px;
}
.card {
    border: 1px solid rgba(255,225,204,.3);
    color: #fff;
    margin: 0 .25rem .5rem;
    padding: .15rem .5rem;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    i {
        color: #DB6939;
        margin-right: .5rem;
    }
}
.general {
    display: flex;
}
.timing {
    label {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
    }
    ul {
        display: flex;
        margin: 0;
    }
}
.price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 108px;
    strong {
        background: rgb(255, 225, 204);
        font-size: 14px;
        border-radius: 3px;
        font-weight: 500;
        color: #29394d;
        padding: .25rem .5rem;
    }
    span {
        margin-top: .5rem;
    }
}
.barred {
    position: relative;
    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: #fe3745;
        top: 40%;
        left: 0;
        transform: rotate(-25deg);
    }
}
.infos {
    font-size: .75rem;
    li {
        display: flex;
        align-items: center;
        i {
            margin-right: .5rem;
            font-weight: bold;
        }
    }
}
.controls {
    display: flex;
}
.btn {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border-radius: 5px;
    font-size: .75rem;
    width: 48%;
}
.btnPrimary {
    color: #fff !important;
    background-color: #06d9b1;
    margin-right: 2%;
    &:hover {
        background-color: #02af8e;
    }
}
.btnSecondary {
    color: #6d7f92 !important;
    background-color: #f1f2f4;
    &:hover {
        background-color: #d8dde2;
    }
}
.slider {
    padding-left: .25rem;
    & > button {
        top: -2rem;
        height: 2rem;
        width: 2rem;
        background-color: #f8f9fa !important;
        border-radius: 50%;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        left: calc(100% - 8rem);
        &:first-child {
            right: 5rem;
            left: calc(100% - 10.5rem)
        }
        &::before {
            font-size: 1.75rem;
        }
    }
    .dots {
        top: -2.125rem;
        display: flex !important;
        right: 11rem;
        justify-content: flex-end;
        li {
            height: 10px;
            width: 10px;
            button {
                padding: 4px;
                height: 8px;
                width: 8px;
            }
        }
    }
}

.explore {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 .5rem 1rem 0;
    a {
        height: 2rem;
        padding: 0 1rem;
        background-color: #fff;
        border: 1px solid #d8dde2;
        border-radius: 2rem;
        user-select: none;
        font-size: .75rem;
        color: #6d7f92;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}