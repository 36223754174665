body {
  a {
    outline: none;
  }
  ul {
    margin-left: 0;
    list-style-type: none;
  }

}
