.close {
  right: -1.125rem;
  z-index: 1;
  width: 2.25rem;
  height: 2.25rem;
  top: -1.125rem;
  background-color: #444;
  border: 1px solid #444;
  border-radius: 50%;
  position: absolute;
}
.btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  &:before,
  &:after {
    content: "";
    width: 2px;
    height: 1.3rem;
    background: #fff;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    border-radius: 30%;
  }
  &:before {
    height: 1.15rem;
    top: 0.55rem;
    left: 50%;
  }
  &:after {
    width: 1.15rem;
    left: 0.55rem;
    height: 2px;
    top: 50%;
  }
}
