@import "styles/imports";

.PageForm {
  background: #fafbfb;
  padding-bottom: 4rem;
  font-size: 0.75rem;
  input {
    background-color: #fff;
    height: auto;
    margin: 0;
    box-shadow: none;

    &:focus {
      background-color: #fff;
      height: auto;
      margin: 0;
      box-shadow: none;
    }
  }
}
.header {
  background-color: $portal-new-very-light-blue;
  padding: 0.75rem 5.125rem;
  display: flex;
  a,
  h1 {
    color: $new-title-color;
    text-transform: uppercase;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    height: 2.25rem;
    padding: 0 1rem;
  }
  h1 {
    color: $portal-new-blue;
    margin: 0;
    font-weight: 600;
  }
  a {
    background-color: $new-very-light-gray;
    border: 1px solid $new-medium-gray;
    border-radius: 5px;
    font-weight: 500;
  }
  .arrow {
    background-color: $new-title-color;
    width: 0.875rem;
    border-radius: 4px;
    height: 2px;
    position: relative;
    margin-right: 0.75rem;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: -1px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      transform: rotate(45deg);
      background-color: $new-title-color;
      top: 3px;
    }
    &:after {
      transform: rotate(-45deg);
      top: -3px;
    }
  }
}
.section {
  width: 100%;
  // background-color: #fff;
}

.sectionContent,
.headerContent {
  padding: 4rem 5.125rem;
  display: flex;
}

.sectionHeader {
  margin-bottom: 42px;
  display: flex;
  align-items: center;
}

.sectionName {
  font-family: $h-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;

  color: #2495e1;
  margin-right: 8px;
}

.sectionBody {
  textarea {
    width: 100%;
    background: #ffffff;
    border: 1px solid #f1f2f4;
    border-radius: 4px;
    resize: none;
    padding: 8px 12px;
    font-family: $body-font-family;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #29394d;

    &::placeholder {
      font-weight: 300;
      color: #bbb;
      font-size: 0.875rem;
    }

    &:hover {
      border-color: #dfe2e6;
    }

    &:focus {
      outline: none;
      border-color: #18a0fb;
      background: #f3faff;
    }
  }
}

.resourcesList {
  display: flex;
}

.resourceContainer {
  display: flex;
  flex-direction: column;
  width: calc(20% - 16px); // - margin
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #f1f2f4;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  padding: 16px;
  margin-right: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid #c7e8fe;
  }

  &Selected {
    background: #c7e8fe;
  }
}

.resourceName {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #29394d;
}

.resourceLogo {
  display: flex;
  padding: 16px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.actions {
  display: flex;
  width: 100%;
  max-width: 1278px;
  margin: auto;
  padding: 2rem 40px;
}

.actionsCancel,
.actionsNext {
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  border-radius: 2rem;
  outline: 0;

  appearance: none;
  border: 0;
  background: transparent;
  line-height: 1;
  text-transform: none;
}

.actionsCancel {
  border: none;
  box-shadow: 0 0 0 0.06rem rgba(0, 0, 0, 0.06),
    0 0.13rem 0.2rem 0 rgba(0, 0, 0, 0.06);
  color: #8d8d8d;

  &:hover {
    color: #3f3f3f;
    box-shadow: 0 0 0 0.06rem rgba(0, 0, 0, 0.06),
      0 0.13rem 0.2rem 0 rgba(0, 0, 0, 0.06);
  }
}

.actionsNext {
  display: flex;
  align-items: center;
  box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  background-image: linear-gradient(104deg, #57d0f2 0%, #5081be 100%);
  font-weight: 600;
  background-size: 100%;

  &:hover {
    background-image: linear-gradient(104deg, #6facf7 0%, #5081be 100%);
  }
}

.formContainer {
  width: 50%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  padding: 23px 26px;
  h2 {
    font-size: 1.125rem;
    text-transform: uppercase;
    color: $portal-new-blue;
    font-weight: 500;
    margin: 0;
  }
  p {
    color: $new-gray;
  }
}

.form {
  background: #f8f9fa;
  border: 1px solid #c7e8fe;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 0px 0px 4px 4px;
  padding: 28px 0;
  margin-bottom: 23px;
}

.formControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  padding: 0 0.8rem;
}

.formLabel {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  &.bold {
    font-weight: 500;
    margin-bottom: 0.875rem;
  }
}

.formInput[type="text"] {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 4px;
  padding: 11px;

  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #29394d;

  &:hover {
    border-color: #dfe2e6;
  }

  &:focus {
    border: 1px solid #18a0fb;
    background: #f3faff;
    box-shadow: none;
  }
}

.formActions {
  width: 100%;
  display: flex;
  padding-top: 18px;
  border-top: 1px solid rgba(178, 188, 198, 0.5);
}

.formCancel {
  background: #fff;
  border: 1px solid rgba(109, 127, 146, 0.5);
  border-radius: 5px;

  color: #6d7f92;
  margin-right: auto;
}

.formDelete {
  background: #fff;
  border: 1px solid rgba(254, 55, 69, 0.3);
  border-radius: 5px;

  color: #fe3745;
  margin-right: 14px;
}

.formSave {
  background: #18a0fb;

  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 4px;

  color: #fff;
}

.formCancel,
.formSave,
.formDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  font-family: $body-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  padding: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.languagesContainer {
  display: flex;
  width: 100%;
}

.language {
  padding: 9px 25px;
}

.condition {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.conditionInput {
  width: 100%;
  margin-right: 0.5rem;
}

.conditionActions {
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 0.5px solid;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:before {
    content: "";
    width: 0.75rem;
    height: 2px;
    border-radius: 25%;
    display: block;
  }
  &.minus {
    border-color: $portal-light-red;
    margin-right: 0.5rem;
    margin-left: auto;
    &:before {
      background-color: $portal-new-red;
    }
  }
  &.plus {
    border-color: $portal-new-light-blue;
    &:before {
      background-color: $portal-new-blue;
    }
    &:after {
      content: "";
      width: 2px;
      height: 0.75rem;
      border-radius: 25%;
      background-color: $portal-new-blue;
      position: absolute;
    }
  }

  &.disabled {
    border-color: #d8dde2;
    opacity: 0.8;
    cursor: not-allowed;

    &:before,
    &:after {
      background-color: #d8dde2;
    }
  }
}

.layout {
  display: flex;

  &_blog {
    flex-wrap: wrap;

    .layoutModel {
      width: 23%;
      height: 155px;
      margin-bottom: 8px;
      overflow: hidden;
    }
  }
}

.layoutModel {
  height: 130px;
  width: 25%;
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    border-color: #18a0fb;
  }

  &.layoutModelSelected {
    border-color: #18a0fb;
    background: #f3faff;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.previewContainer {
  width: 50%;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 23px 26px;
  margin-left: 16px;

  iframe {
    border: none;
    appearance: none;
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: 0 0;
  }
}
.conditions {
  background-color: $new-light-gray;
  padding: 1rem 0.8rem;
  margin-bottom: 0.75rem;
}
.conditionsTop {
  display: flex;
  justify-content: space-between;
}
.albumConditions {
  background-color: $new-light-gray;
  padding: 1rem 0.8rem;
  margin-bottom: 0.75rem;
  max-height: 350px;
  overflow-y: scroll;
}
.displayMagazine {
  display: flex;

  &__label {
    font-size: 12px;
    line-height: 14px;
    margin-right: 5px;
  }
}

.portalBloc {
  padding: 0.625rem 1rem;
  color: $new-gray;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid $new-light-gray;
  // box-shadow: $new-box-shadow;

  &:hover,
  &.selected {
    border-color: #18a0fb;
  }

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $new-light-gray;
  }

  &_model {
    height: 60px;
    width: 100%;
    overflow: hidden;
  }

  &_newsmodel {
    min-height: 60px;
    width: 100%;
  }

  &_domain {
    margin-bottom: 0.75rem;

    input {
      background-color: #f8f9fa !important;
    }
  }
}

.geobox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &_left {
    width: 50%;
    margin-right: 1rem;
  }

  &_right {
    width: 50%;
  }
}

.mapbox {
  height: 300px;
  margin-bottom: 1rem;
}

.schedule {
  background-color: #fff;

  &_row {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    border-bottom: 1px solid #f1f2f4;
  }

  &_header {
    font-weight: 500;
    text-align: center;
  }

  &_day {
    width: 20%;
    text-align: center;
  }

  &_hours {
    width: 32%;
  }

  &_action {
    width: 16%;
  }

  &_input {
    display: flex;
    align-items: center;

    span {
      margin: 0 10px;
    }

    input {
      background: #ffffff;
      border: 1px solid #f1f2f4;
      border-radius: 4px;
      padding: 7px 5px;
      font-size: 12px;
      line-height: 14px;
      color: #18a0fb;
      width: 50px;

      &:hover {
        border-color: #dfe2e6;
      }

      &:focus {
        border: 1px solid #18a0fb;
        background: #f3faff;
        box-shadow: none;
      }
    }
  }

  &_nowork {
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      margin-left: 0.75rem;
    }
  }

  &_btn {
    border: 1px solid #18a0fb;
    background-color: #fff;
    border-radius: 4px;
    display: block;
    cursor: pointer;
    line-height: 14px;
    text-align: center;
    padding: 5px 2px;
    margin: 0 1rem 0 0;

    &:hover {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}
