@import "styles/base/variables";
@import "styles/base/functions";
@import "styles/base/mixins";

.webtoolContent {
  margin-bottom: 1.875rem;
  position: relative;
  height: 10.375rem;
  background-color: $new-very-light-gray;
  border-radius: 5px 3px 5px 5px;
  box-shadow: $new-box-shadow;
  transition: border 0.2s ease-in-out;
  &:hover {
    border: 1px solid $new-light-gray;
    .actions ul {
      transform: translateX(0);
    }
  }

  h2,
  h3 {
    margin: 0;
    color: $new-title-color;
    line-height: 1;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.125rem;
    padding-top: 0.3rem;
    max-height: 3.125rem;
    cursor: pointer;
    a {
      color: $new-title-color;
      transition: color 0.1s ease-in-out;
      &:hover {
        color: $portal-new-blue;
      }
    }
  }
  h3 {
    padding-left: 0.25rem;
    border-left: 2px solid;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25;
    margin: 0.5rem 0 0 0.875rem;
  }

  .category {
    display: flex;
    justify-content: space-between;
    //margin-bottom: .3rem;
    span {
      color: #fff;
      font-weight: 500;
      font-size: 0.625rem;
      height: 1rem;
      padding: 0 0.5rem;
      border-radius: 2px;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    height: 9rem;
    justify-content: space-between;
  }

  .tool,
  .favorite {
    position: relative;
    .action {
      display: none;
    }
    &.activated {
      .webtoolContent {
        //padding-right: 1.25rem;
        cursor: pointer;
      }
      h2 a:hover {
        color: inherit;
      }
      .action {
        display: block;
        position: absolute;
        right: 1rem;
        top: 43%;
      }
      .actions li {
        display: none;
      }
    }
  }
  .tool {
    &.activated {
      .webtoolContent {
        background-color: $portal-new-very-light-blue;
        &:hover {
          border-color: $portal-new-blue !important;
        }
      }
      .action {
        &:after {
          top: -0.4rem;
          right: 0.4rem;
        }
      }
    }
  }
  .favorite {
    &.activated {
      .webtoolContent {
        background-color: $portal-new-very-light-red;
        border-color: transparent;
        &:hover {
          border-color: $portal-new-red !important;
        }
      }
      .action {
        margin: 0;
      }
    }
  }
  .tags {
    border-top: 1px solid $new-light-gray;
    display: flex;
    padding: 0.5rem 0;
    margin: 0 0.625rem;
    overflow-x: auto;
    li {
      font-size: 0.625rem;
      text-transform: uppercase;
      height: 1.25rem;
      padding: 0 0.625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $new-light-gray;
      border-radius: 2px;
      margin-left: 0.25rem;
      min-width: max-content;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .content {
    display: flex;
    height: 100%;
    overflow: hidden;
    align-items: center;
    & > i {
      font-size: 2.25rem;
      padding: 0 0.825rem;
      color: $new-gray;
      width: 20%;
    }

    p {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.2;
      max-height: 2 * 1.2 * 0.75rem;

      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    p {
      /* Do not remove next comment */
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
    &.noCom {
      //position: relative;
      p {
        max-height: 3.5rem;
      }
    }
  }
  .textes {
    width: 67%;

    :global .rdw-editor-wrapper {
      max-height: 30px;
    }
  }
  .actions {
    width: 13%;
    ul {
      width: 2.625rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      transform: translateX(100%);
      transition: transform 0.2s ease-in-out;
    }
    li {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $new-title-color;
      font-weight: 300;
      box-shadow: $new-box-shadow;
      border: 0.5px solid $gray-200;
      background-color: #fff;
      position: relative;
      top: 0.5rem;
      cursor: pointer;
      z-index: 2;
      transition: all 0.1s ease-in-out;
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $new-title-color;
        font-weight: bold;
      }
      &:hover {
        z-index: 3;
        background-color: $new-gray;
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
  }
  .domaine {
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    i {
      margin-right: 0.375rem;
    }
  }
}
