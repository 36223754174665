@import "styles/imports";

.container {
  background-color: #000;
  width: 100%;
  height: 35rem;
  margin: 0;
  background: url('/img/404.jpg') no-repeat;
  color: #fff;
  font-size: 1.5rem;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-bottom: 2rem;

  p {
    margin-bottom: 1.5rem;
  }

  .number {
    font-size: 10rem;
    color: #fff;
  }
}

.column {
  height: 100%;
  width: calc(100% / 3);
}

.message {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  border-right: 2rem solid #fff;
  border-left: 2rem solid #fff;
  text-align: center;
  padding-top: 4rem;

  color: #8d8d8d;
}

.title {
  font-weight: bold;
}

.description {
  font-size: .9rem;
}
