@import "styles/imports";

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  background-color: rgba(41, 57, 77, 0.4);
}

.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContentAfterOpen {
  .confirm {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .confirm {
    top: 50%;
    opacity: 0;
  }
}

.confirm {
  position: relative;
  top: 50%;
  width: 632px;
  height: 307px;
  opacity: 0;
  background: #F8F9FA;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3C4E64;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 50%;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  background: #FFF5F6;
  border-radius: 0px 0px 5px 5px;
  border-bottom: 1px solid #FFDBDD;

  font-family: $body-font-family;
  font-size: 22px;
  line-height: 26px;
  color: #F4515D;

  .icon {
    position: absolute;
    bottom: -45px;
    right: 50%;
    transform: translateX(50%);

    width: 90px;
    height: 45px;

    color: #F4515D;
    background: #FFF5F6;
    border: 1px solid #FFDBDD;
    border-top: none;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.body {
  width: 100%;
  height: 152px;
  padding-top: 82px;

  border-bottom: 1px solid #F1F2F4;

  font-family: $body-font-family;
  font-size: 18px;
  line-height: 21px;
  color: #29394D;
  text-align: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95px;
}

.yes,
.no,
.deleting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 5px;
  font-family: $body-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.no {
  width: 126px;
  background: rgba(109, 127, 146, 0.6);
  margin-right: 10px;
}

.yes,
.deleting {
  width: 294px;
  background: #FF636E;
}

.deleting {
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
}
