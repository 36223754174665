.filter {
  // position: fixed;
  max-height: 100vh;
  overflow: auto;
  .part {
    background: #fff;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 0 1rem;
    &.first-child {
      margin-top: 0;
    }
  }
  h5 {
    margin: 0;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f1f2f4;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #2495e1;
  }
  ul {
    margin: 0;
    padding: 0.75rem 0;
    li {
      color: #3c4e64;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      overflow: hidden;
    }
  }
  .categories {
    li {
      justify-content: space-between;
      &.selected,
      &:hover {
        font-weight: 600;
      }
    }
  }
  .themes,
  .tags {
    display: flex;
    flex-wrap: wrap;
    li {
      background: #f1f2f4;
      height: 26px;
      padding: 0 0.75rem;
      &.selected,
      &:hover {
        background-color: #2495e1;
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .themes {
    li {
      border-radius: 2rem;
      margin: 0 6px 6px 0;
    }
  }
  .tags {
    li {
      margin: 0 4px 4px 0;
    }
  }
  .count {
    width: 2.5rem;
    height: 1.125rem;
    border-radius: 2px;
    color: #fff;
    margin-bottom: 0.75rem;
    background-color: #f1f2f4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .others {
    height: 0;
    transition: height 0.2s ease-in-out;
  }
  .seeMore {
    height: 2rem;
    border-top: 1px solid #f1f2f4;
    display: flex;
    justify-content: flex-end;
    span {
      color: #2495e1;
      font-weight: 300;
      font-size: 0.75rem;
      position: relative;
      top: -10px;
      background-color: #fff;
      padding-left: 10px;
      cursor: pointer;
    }
    i {
      margin-left: 5px;
      font-size: 11px;
      position: relative;
      top: 1px;
      font-weight: bold;
    }
  }
  .fetching {
    li {
      width: 6rem;
      height: 26px;
      background-color: #eee;
      margin-bottom: 6px;
    }
  }
  .categoriesFetching,
  .tagsFetching {
    li {
      border-radius: 2px;
    }
  }
  .themesFetching,
  .tagsFetching {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 6px;
    }
  }
  .themesFetching {
    li {
      border-radius: 2rem;
    }
  }
}
